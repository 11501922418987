import React from 'react';

import * as S from './styles';

export const PowerReviewsDisplay = ({ id }: { id: string }) => {
  return (
    <S.ReviewDisplay
      id={id}
      data-testid="powerReviewsDisplay"
    ></S.ReviewDisplay>
  );
};

export default PowerReviewsDisplay;
