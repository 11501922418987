import { Container, Row, Col, Button } from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import { getSrc } from 'gatsby-plugin-image';
import React, { useContext, useEffect, useState, useLayoutEffect } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';

import { logError } from 'utils/helpers';
import { ProductInfoContext } from 'utils/helpers/pdpContexts';
import PowerReviewsDisplay from '../../../components/PDP/PowerReviewsDisplay';
import * as S from './styles';

declare global {
  interface Window {
    POWERREVIEWS: any;
  }
}

// src for pulling in PowerReviews base widget that will have our style overrides applied
const powerReviewsScriptSrc = '//ui.powerreviews.com/stable/4.0/ui.js';
// selectors needed for base widget overrides
const wouldRecommendSelector = '.pr-rd-bottomline';
const sortReviewsSelector = '#pr-rd-sort-by';
const nextPageSelector = '.pr-rd-pagination-btn';
const wouldRecommendText = 'Would recommend to a friend';
const showRecommendationClass = 'showRecommendation';
const reviewDisplayNode = 'pr-reviewdisplay';

const appendScript = (src: string) => {
  return new Promise(function (resolve, reject) {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.addEventListener('load', function (e) {
      resolve(e);
    });
    script.addEventListener('error', function (e) {
      reject(e);
    });
    document.body?.appendChild(script);
  });
};

export interface ReviewsContainerProps {
  content: {
    title: string;
    modalButtonText: string;
  };
}

// This Reviews Container appends the script to load the base Power Reviews Widget.
// Once this widget is inserted into the DOM via that script, we apply a stylesheet of overrides
// to achieve desired look.  Tried to use only CSS, but in a few cases we need to do some DOM manipulation
// with js.  This section also creates the Product JSON-LD structured data for SEO
export const ReviewsContainer: React.FC<ReviewsContainerProps> = ({
  content,
}) => {
  if (!content) return null;

  const { title, modalButtonText } = content;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [hasRendered, setHasRendered] = useState<boolean>(false);

  /**
   * add a piece of state to tell us when power reviews has loaded.
   * we will use this to determine whether or not to render after
   * the modal is opened or closed
   */
  const [prLoaded, setPrLoaded] = useState<boolean>(false);

  const {
    name,
    productId,
    price,
    heroImages,
    description,
    sku,
    slug,
    reviewData = {
      average_rating: 0,
      review_count: 0,
      rating_histogram: [0, 0, 0, 0, 0],
      recommended_ratio: 0,
    },
  } = useContext(ProductInfoContext);

  const productImage = getSrc(heroImages[0].gatsbyImageData);
  const {
    average_rating: averageRating,
    review_count: reviewCount,
    rating_histogram,
    recommended_ratio,
  } = reviewData;

  const recommendedPercentage = recommended_ratio * 100;
  const pr_merchant_id = process.env.POWER_REVIEWS_MERCHANT_ID;
  const pr_merchant_group_id = process.env.POWER_REVIEWS_MERCHANT_GROUP_ID;
  const pr_page_id = productId;
  const pr_api_key = process.env.POWER_REVIEWS_API_KEY;

  // We don't want to show the recommendation text when they don't recommend
  const updateRecommendSection = (node: Element) => {
    const text = node.getElementsByTagName('span')[1];
    if (text.innerText.includes('No')) {
      node.remove();
    } else {
      text.innerText = wouldRecommendText;
      node.classList.add(showRecommendationClass);
    }
  };

  const powerReviewsOptions = {
    api_key: pr_api_key,
    locale: 'en_US',
    merchant_group_id: pr_merchant_group_id,
    merchant_id: pr_merchant_id,
    page_id: pr_page_id,
    // Disable Product Schema generation from Power Reviews as we generate
    ENABLE_CLIENT_SIDE_STRUCTURED_DATA: false,
    // once powerreviews widget has been rendered, we execute this callback
    // to update styles, and setup event listeners so that they update when the user
    // changes the sort or paginates to another page
    // https://help.powerreviews.com/Content/Platform/JavaScript%20Reference%20Guide.htm#Callback
    on_render: function (config: any) {
      if (config.component === 'ReviewDisplay') {
        document
          .querySelectorAll(wouldRecommendSelector)
          .forEach((node) => updateRecommendSection(node));

        // setting event listener to update DOM when user paginates
        // the powerreviews widget requests out to an external API and does not expose
        // a callback for when that request resolves, so we need to wrap them in a setTimeout (cringe)
        document.querySelectorAll(nextPageSelector).forEach((node) =>
          node.addEventListener('click', () => {
            setTimeout(() => {
              document
                .querySelectorAll(wouldRecommendSelector)
                .forEach((node) => updateRecommendSection(node));
            }, 300);
          }),
        );

        document
          .querySelector(sortReviewsSelector)
          ?.addEventListener('change', () => {
            setTimeout(() => {
              document
                .querySelectorAll(wouldRecommendSelector)
                .forEach((node) => updateRecommendSection(node));
            }, 300);
          });
      }
    },
  };

  const preloadPowerReviews = () => {
    window.POWERREVIEWS?.display?.render({
      ...powerReviewsOptions,
      components: { ReviewDisplay: 'preloaded-pr' },
    });
  };

  const renderPowerReviews = () => {
    window.POWERREVIEWS?.display?.render({
      components: {
        ReviewDisplay: reviewDisplayNode,
      },
      ...powerReviewsOptions,
    });
  };

  const handleOpen = () => {
    setOpenModal(true);
    if (!hasRendered) {
      // on initial render - resize event ensures mediaQuery style overrides are applied
      window.dispatchEvent(new Event('resize'));
      setHasRendered(true);
    }
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.OPEN_REVIEWS_MODAL,
      data: { name, productId },
    });
  };

  useEffect(() => {
    if (!prLoaded) {
      // preload power reviews on page load
      const loadPowerReviews = appendScript(powerReviewsScriptSrc);
      loadPowerReviews
        .then(() => {
          preloadPowerReviews();
          setPrLoaded(true);
        })
        .catch((e: any) =>
          logError(
            `There was an issue loading the powerreviews script from CDN`,
            {
              component: 'ReviewsContainer',
              function: 'useEffect',
              stackTrace: e,
            },
          ),
        );
    }
  }, []);

  useLayoutEffect(() => {
    // render powerreviews when user opens modal
    openModal && renderPowerReviews();
  }, [openModal]);

  return (
    <SectionWrapper data-testid="powerReviewsSection" id="reviewsSection">
      <Container>
        <Row>
          <Col xs>
            <S.OverviewContent>
              <S.OverviewTitle>{title}</S.OverviewTitle>
              <S.Stars rating={averageRating} numOfReviews={reviewCount} />
              {reviewCount ? (
                <>
                  <S.RecommendedText>
                    {recommendedPercentage}% would recommend to a friend
                  </S.RecommendedText>
                  <S.RatingsBarContainer>
                    <S.RatingsBar
                      title="5 Star"
                      reviewTotal={reviewCount}
                      reviewCount={rating_histogram[4]}
                    />
                    <S.RatingsBar
                      title="4 Star"
                      reviewTotal={reviewCount}
                      reviewCount={rating_histogram[3]}
                    />
                    <S.RatingsBar
                      title="3 Star"
                      reviewTotal={reviewCount}
                      reviewCount={rating_histogram[2]}
                    />
                    <S.RatingsBar
                      title="2 Star"
                      reviewTotal={reviewCount}
                      reviewCount={rating_histogram[1]}
                    />
                    <S.RatingsBar
                      title="1 Star"
                      reviewTotal={reviewCount}
                      reviewCount={rating_histogram[0]}
                    />
                  </S.RatingsBarContainer>
                  <Button
                    appearance="secondary"
                    onClick={handleOpen}
                    data-testid="viewAllReviews"
                  >
                    {modalButtonText}
                  </Button>
                </>
              ) : (
                <S.CaptionText>no reviews yet</S.CaptionText>
              )}
              <div id="preloaded-pr" style={{ display: 'none' }} />
              {openModal ? (
                <S.Modal open={openModal} openHandler={setOpenModal}>
                  <S.ReviewModalTitle>{name}</S.ReviewModalTitle>
                  <PowerReviewsDisplay id={reviewDisplayNode} />
                </S.Modal>
              ) : null}
            </S.OverviewContent>
          </Col>
        </Row>
      </Container>
    </SectionWrapper>
  );
};

export default ReviewsContainer;
