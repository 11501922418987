import { typography, colors, size, mediaQueries } from '@everlywell/leaves';
import { css } from 'styled-components';

import empty from './icons/empty@2x.png';
import full from './icons/full@2x.png';
import half from './icons/half@2x.png';
import recommend from './icons/recommend-to-friend.svg';

// Overrides for the default power reviews widget that gets injected on the PDP review section
// more information on overrides for power reviews
// https://help.powerreviews.com/Content/Display%203/Styling%20Display%203.htm
export const ReviewOverrides = css`
  #pr-image-display {
    display: none;
  }

  #pr-review-snapshot .pr-review-snapshot-header-intro {
    display: none;
  }

  #pr-review-snapshot .pr-snippet-rating-decimal {
    display: none;
  }

  #pr-review-snapshot .pr-snippet {
    display: flex;
    align-items: center;

    .pr-snippet-read-and-write {
      margin-top: 0;
    }
  }

  #pr-review-snapshot .pr-snippet-write-review-link {
    display: none;
  }

  #pr-review-snapshot .pr-review-snapshot-faceoff {
    display: none;
  }

  .pr-rd-review-header-contents {
    display: none;
  }

  #pr-review-snapshot .pr-snippet .pr-snippet-stars-container .pr-star-v4 {
    height: 24px;
    width: 24px;
    outline: none;
    box-shadow: none;
  }

  #pr-review-snapshot .pr-snippet .pr-snippet-review-count {
    font-size: 18px !important;
    font-family: ${typography.type.nexa};
    line-height: 24px;
    letter-spacing: 0.45px;
    color: #252228;
  }

  #pr-review-snapshot .pr-review-snapshot-header {
    margin-bottom: ${size.lg}px;

    ${mediaQueries.forTabletHorizontalUp} {
      margin-bottom: ${size.xl3}px;
    }

    .pr-review-snapshot-snippets {
      .pr-snippet-stars-reco-inline {
        display: flex;
        flex-direction: column;

        .pr-snippet-stars-reco-reco {
          margin-top: ${size.sm}px;

          .pr-snippet-reco-to-friend {
            max-width: none;

            .pr-snippet-reco-to-friend-percent {
              ${typography.subtitle}
              font-family: ${typography.type.nexa};
              margin-right: 0;

              .pr-checkbox-icon {
                display: none !important;
              }

              .pr-reco {
                margin-right: 0;

                .pr-reco-value {
                  font-size: 16px;
                  color: ${colors.gray5};
                  margin-right: 0;
                }
              }
            }
            .pr-reco-to-friend-message {
              visibility: hidden;
              max-width: none;
              color: ${colors.gray5};
              &::before {
                ${typography.subtitle}
                font-family: 16px;
                font-family: ${typography.type.nexa};
                visibility: visible;
                content: 'would recommend to a friend';
              }
            }
          }
        }
      }
    }
  }

  #pr-review-snapshot .pr-review-snapshot-block-container {
    /* this is the container that houses the histogram */
    display: none;
    .pr-review-snapshot-block-pros {
      display: none;
    }
    .pr-review-snapshot-block-cons {
      display: none;
    }
  }

  #pr-review-display {
    position: relative;
    background: transparent;

    ${mediaQueries.forPhoneOnly} {
      padding-bottom: ${size.xl2}px;
    }

    /* Header Stars and Sorting Dropdown */
    #pr-rd-main-header {
      padding-top: 0;

      &::before {
        font-family: ${typography.type.nexa};
        content: 'Sort By';
        ${typography.bodyTextSmall}

        ${mediaQueries.forTabletHorizontalUp} {
          display: none;
        }
      }

      ${mediaQueries.forTabletHorizontalUp} {
        position: absolute;
        right: 0;
        top: -${size.xl4}px;
      }

      .pr-rd-review-header-sorts {
        padding: 0;
        width: auto;

        .pr-rd-sort-group {
          position: relative;
          padding-top: 10px;

          &::after {
            content: '';
            color: ${colors.teal4};
            width: 12px;
            height: 12px;
            top: 22px;
            right: 20px;
            border-bottom: 0.2rem solid ${colors.teal4};
            border-right: 0.2rem solid ${colors.teal4};
            transform: rotate(45deg);
            pointer-events: none;
            position: absolute;
          }

          select#pr-rd-sort-by {
            appearance: none;
            font-family: ${typography.type.nexa};
            ${typography.bodyText};
            border: 2px solid ${colors.green2};
            padding-right: ${size.lg}px;
            padding-left: ${size.md}px;
            box-shadow: 4px 4px 6px -5px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            width: 100%;

            &:focus {
              border: solid 2px ${colors.green5};
              outline: none;
            }
          }
        }
      }
    }

    /* this is a Review Block */
    .pr-review {
      .pr-rd-header {
        display: flex;
        flex-direction: column-reverse;
        background: transparent;
        margin-bottom: ${size.xs2}px;

        .pr-rd-star-rating {
          .pr-snippet-rating-decimal {
            display: none;
          }

          .pr-snippet-stars {
            margin-bottom: 0;
            background: transparent;

            .pr-rating-stars {
              .pr-star-v4 {
                width: ${size.md}px;
                height: ${size.md}px;
              }
            }
          }
        }

        .pr-rd-review-headline {
          font-family: ${typography.type.nexa};
          ${typography.subtitle};
          margin-bottom: ${size.xs1}px;
          margin-top: 0;
          margin-left: 0;
        }
      }

      .pr-rd-description {
        display: flex;
        flex-direction: column !important;

        .pr-rd-side-content-block {
          display: flex;
          margin-bottom: 0;
          flex-direction: row;
          flex-wrap: wrap;

          ${mediaQueries.forTabletHorizontalUp} {
            flex-direction: row;
          }

          /* required to override a Power Review Media Query that breaks layout */
          @media screen and (min-width: 840px) and (max-width: 900px) {
            flex-direction: column;
            align-items: flex-start;
          }

          .pr-rd-reviewer-details {
            display: flex;
            flex-direction: row-reverse;
            font-family: ${typography.type.nexa};
            flex-wrap: wrap-reverse;
            justify-content: flex-end;
            margin-right: ${size.sm}px;

            .pr-rd-author-submission-date {
              padding-left: ${size.xs2}px;
              ${typography.subtitle}
              font-size: 16px;
              &::before {
                content: ' - ';
              }
              span {
                display: none;
              }
            }

            .pr-rd-author-nickname {
              ${typography.subtitle}
              font-size: 16px;
              span {
                span.pr-rd-bold {
                  display: none;
                }
              }
            }

            .pr-rd-author-location {
              display: none;
            }
          }

          .pr-rd-reviewer-type {
            display: flex;
            align-items: center;
            min-width: 140px;

            .pr-rd-badging-text {
              font-family: ${typography.type.nexa};
              ${typography.subtitle}
              font-size: 16px;
              color: ${colors.green5};
              text-transform: lowercase;
            }

            .pr-badging-icon {
              width: ${size.md}px;
              height: ${size.md}px;

              svg {
                circle {
                  fill: ${colors.green5};
                }
              }
            }
          }
        }

        .pr-rd-description-text {
          font-family: ${typography.type.nexa};
          ${typography.bodyTextSmall}
        }
      }

      /* Recommend and Helpful/Report Buttons */
      .pr-rd-footer {
        background: transparent;

        .pr-rd-bottomline {
          display: none;
          align-items: flex-end;
          opacity: 0;

          &.showRecommendation {
            display: flex;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
            transition-delay: 0.3s;
          }

          &::before {
            content: '';
            display: inline-block;
            background-image: url(${recommend});
            background-size: cover;
            height: 2rem;
            width: 1.8rem;
            margin-right: ${size.xs1}px;
          }

          span {
            font-family: ${typography.type.nexa};
            ${typography.subtitle};
            color: ${colors.gray4};
            font-size: ${size.md}px;

            &.pr-rd-bold {
              display: none;
            }
          }
        }

        .pr-rd-helpful-action {
          .pr-rd-helpful-action-group {
            display: flex;

            .pr-rd-helpful-action-legend {
              display: none;
            }

            .pr-rd-helpful-action-btn-group {
              .pr-helpful-btn {
                font-family: ${typography.type.nexa};
                ${typography.buttonTextSmall}
                padding: ${size.xs2}px 0;
                min-width: 9.438rem;
                border: 1px solid ${colors.green2};
                border-radius: 2px;
                height: 2rem;
                position: relative;
                z-index: 0;

                &:hover,
                &:focus {
                  background-color: rgba(214, 235, 221, 0.2);
                  border-color: ${colors.green4};
                  outline: none;
                }

                .pr-thumbs-icon {
                  display: none;
                }

                .pr-helpful-count {
                  position: absolute;
                  top: 4px;
                  left: 99px;
                  color: ${colors.green5};
                  font-family: ${typography.type.nexa};
                  ${typography.buttonTextSmall}

                  &::after {
                    content: ')';
                  }
                }

                &.pr-helpful-yes {
                  &::before {
                    content: 'Helpful (';
                    margin-left: -18px;
                  }
                }

                &.pr-helpful-no {
                  display: none;
                }
              }
            }

            .pr-rd-flag-review-container {
              height: 2rem;
              display: flex;
              justify-content: center;
              align-self: center;
              margin-left: ${size.sm}px;

              .pr-rd-flag-review-btn {
                color: ${colors.green5};
                font-family: ${typography.type.nexa};
                ${typography.buttonTextSmall}
                min-width: 9.438rem;
                border: 1px solid ${colors.green2};
                text-decoration: none;
                font-size: 0;
                border-radius: 2px;
                width: 100%;
                text-align: center;
                height: 100%;
                position: relative;

                &::after {
                  position: absolute;
                  content: 'Report';
                  font-size: 16px;
                  left: 46px;
                  top: 4px;
                }

                &:hover,
                &:focus {
                  background-color: rgba(214, 235, 221, 0.2);
                  border-color: ${colors.green4};
                }
              }

              .pr-media-modal {
                .form-control:focus {
                  box-shadow: 0 0 0 2px #4d90fe;
                  outline: 2px solid transparent;
                  outline-color: ${colors.teal4};
                }

                .pr-flag-review-btn-cancel {
                  font-family: ${typography.type.nexa};
                  font-size: 1rem;
                  line-height: 2;
                }

                .pr-flag-review-btn-submit {
                  background-color: ${colors.green5};
                  font-family: ${typography.type.nexa};
                  ${typography.buttonTextSmall}
                  color: ${colors.white};
                  text-decoration: none;
                  font-size: 0;
                  text-align: center;

                  &::after {
                    content: 'Report';
                    font-size: 1rem;
                    line-height: 2;
                  }

                  &:hover {
                    background-color: ${colors.green4};
                  }
                  &:focus {
                    background-color: ${colors.green5};
                  }
                }

                a,
                button:focus {
                  box-shadow: none;
                  outline: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .pr-rd-main-footer {
    .pr-rd-content-block {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .pr-rd-review-position {
        font-family: ${typography.type.nexa};
        ${typography.bodyTextSmall};
        margin-bottom: 0;
        position: absolute;

        span:first-of-type {
          display: none;
        }
      }

      .pr-rd-to-top {
        display: none;
      }

      .pr-rd-pagination {
        border: none;
        display: flex;
        width: 344px;

        &:hover,
        &:focus {
          a {
            cursor: pointer;
          }
        }

        .pr-rd-pagination-btn {
          display: block;
          color: ${colors.green5};
          font-family: ${typography.type.nexa};
          ${typography.buttonTextSmall}
          border: 1px solid ${colors.green2};
          text-decoration: none;
          border-radius: 2px;
          min-width: 7.375rem;
          max-width: 7.375rem;
          height: 2rem;
          position: absolute;
          top: 0;
          font-size: 0;
          padding: 0;

          &::after {
            content: 'Previous';
            position: absolute;
            font-size: 16px;
            left: 24px;
            top: 4px;
          }

          &[aria-label='Next'] {
            right: 0;
            &::after {
              left: 38px;
              content: 'Next';
            }
          }

          &[aria-label='Previous'] {
            left: 0;
          }

          &:hover,
          &:focus {
            background-color: rgba(214, 235, 221, 0.2);
            border-color: ${colors.green4};
          }
        }

        span {
          display: none;
        }
      }
    }
  }

  /* necessary for linting ts-styled-plugin  */
  ${css`
    @-moz-document url-prefix() {
      .pr-rd-main-footer {
        padding-bottom: ${size.xl3}px;
      }
    }
  `}

  .p-w-r .pr-helpful-active {
    background-color: rgba(214, 235, 221, 0.2) !important;
    border-color: ${colors.green4} !important;
  }

  .p-w-r .pr-star-v4-100-filled {
    background-image: url(${full});
  }

  .p-w-r .pr-star-v4-75-filled {
    background-image: url(${half});
  }

  .p-w-r .pr-star-v4-50-filled {
    background-image: url(${half});
  }

  .p-w-r .pr-star-v4-25-filled {
    background-image: url(${half});
  }

  .p-w-r .pr-star-v4-0-filled {
    background-image: url(${empty});
  }

  .p-w-r .pr-snippet-reco-to-friend div,
  .p-w-r .pr-snippet-reco-to-friend span {
    display: inline !important;
  }
`;
