import styled from 'styled-components';

import {
  size,
  StarReviews,
  mediaQueries,
  typography,
  Modal as RawModal,
  RatingsBar as BaseRatingsBar
} from '@everlywell/leaves';

export const Section = styled.section`
  padding-bottom: ${size.xl4}px;
`;

export const OverviewContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const OverviewTitle = styled.h3`
  ${typography.h3Text};
`;

export const ReviewSnippet = styled.div`
  margin-top: ${size.lg}px;
`;

export const ReviewModalTitle = styled.h4`
  ${typography.h4Text};
  font-weight: ${typography.weight.regular};
  margin-bottom: ${size.lg}px;

  ${mediaQueries.forTabletHorizontalUp} {
    ${typography.h3Text};
  }
`;

export const CaptionText = styled.p`
  margin-top: ${size.sm}px;
  ${typography.captionText};
`;

export const Stars = styled(StarReviews)`
  margin-top: ${size.lg}px;
  flex-direction: row;
  align-items: center;

  /* Designs require overriding the StarReviews component to match Desktop styles in specs - this component is used on PDP Hero, and in
  product cards so we can't make this update the base component itself without impacting those other consuming components - we need
  to better define this component so we don't have so many override customizations to our own components */
  ${mediaQueries.forTabletHorizontalUp} {
    svg {
      width: 27px;
      height: 26px;
    }
    p {
      /* TODO: font-style that is not linked to a zeplin component */
      font-size: 22.8px;
      line-height: 35.2px;
      letter-spacing: 0.8px;
      margin-left: ${size.xs1}px;
    }
  }

  ${mediaQueries.forDesktopUp} {
    margin-top: ${size.md}px;
  }
`;

export const RatingsBarContainer = styled.div`
  max-width: 530px;
  width: 100%;
`;

export const RatingsBar = styled(BaseRatingsBar)`
  margin-bottom: 20px;
  &:last-child {
      margin-bottom: ${size.xl1}px;
    }
  ${mediaQueries.forTabletHorizontalUp} {
    &:last-child {
      margin-bottom: ${size.xl4}px;
    }
  }
`;

export const RecommendedText = styled.p`
  ${typography.subtitle};
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: ${size.lg}px;
`;

export const Modal = styled(RawModal)`
  button {
    z-index: 2;
  }
`;
